.ticker {
  padding: 60px 0;
  background-color: $button-color;
  color: $light-silver;

  &__title {
    text-align: center;
    margin-bottom: 36px;
  }

  &__container {
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
  }

  &--top {
    margin-bottom: 24px;
  }
  &--bottom {
    padding-bottom: 96px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    animation: scroll 50s linear infinite;
    -webkit-animation: scroll 50s linear infinite;
  }

  &__item {
    margin: 0 40px;
    font-family: $font-orbitron;
    font-size: 120px;
    text-transform: uppercase;
    color: $button-color;
    -webkit-text-stroke: 1px $light-silver;
    text-stroke: 1px $light-silver;
    white-space: nowrap;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}
