.form {
  &__textarea-wrapper {
    margin-bottom: 0;

    textarea {
      resize: none;
      display: block;
      font-family: 'Play', sans-serif;
    }
  }

  &__checkbox-wrapper {
    margin: 32px 0;
  }
}
