.advantages {
  padding: 96px 0;
  background-color: $button-color;
  color: $light-silver;
  overflow: hidden;

  &__title {
    margin-bottom: 24px;
    text-align: left;
  }

  &__section-item {
    margin-bottom: 40px;
    height: max-content;
  }

  &__section-img {
    display: block;
    width: 100%;
    height: 30%;
  }

  &__section-title {
    font-size: 18px;
    margin-bottom: 16px;
    font-family: $font-orbitron;
    line-height: 1.5;
  }

  &__section-info {
    font-size: 16px;
    line-height: 1.2;
  }
}

@media (min-width: $sm) {
  .advantages {
    &__section-img {
      width: 60%;
      margin: 0 auto;
    }
  }
}

@media (min-width: $md) {
  .advantages {
    &__section-wrapper {
      display: flex;
      justify-content: space-between;
    }
    &__section-img {
      width: 80%;
      height: 30%;
    }
    &__section-item {
      margin-bottom: 0;
      max-width: 45%;
    }
  }
}

@media (min-width: $lg) {
  .advantages {
    padding: 136px 0;

    &__info-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__text {
      width: 45%;
    }

    &__section-title {
      font-size: 24px;
    }

    &__section-info {
      font-size: 20px;
    }
  }
}
