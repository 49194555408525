.contacts {
  padding: 104px 0;
  background-color: white;
  color: $text-dark;

  &__wrapper {
    position: relative;
  }

  &__title {
    margin-bottom: 32px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px 0 0 16px;
  }

  &__img-wrapper {
    width: 90%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  &__form-wrapper {
    margin-left: auto;
    max-width: 100%;
    padding: 64px 0;
  }

  &__form {
    background-color: #ffffffd4;
    position: relative;
    z-index: 5;
    padding: 40px 16px;
    border-radius: 16px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
    backdrop-filter: blur(4px);
  }

  &__input {
    background-color: #ffffffb8;
  }
}

@media (min-width: $sm) {
  .contacts {
    &__form-wrapper {
      max-width: 70%;
    }
  }
}

@media (min-width: $md) {
  .contacts {
    padding: 136px 0;

    &__form-wrapper {
      max-width: 50%;
    }

    &__img-wrapper {
      width: 70%;
    }
  }
}
