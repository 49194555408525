// reset

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

//----

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: $background;
  color: $text-dark;
  font-family: 'Play', sans-serif;
  margin: 0;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  font-family: $font-orbitron;
  margin-top: 0;
  text-align: center;
}

h1,
h2 {
  line-height: 110%;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 40px;
  line-height: 120%;
}

.section-title {
  font-size: 40px;
  line-height: 1.5;
  font-family: $font-orbitron;
}

.section-text {
  font-size: 20px;
  line-height: 1.4;
}

a {
  text-decoration: none;

  &:visited {
    color: inherit;
  }
}

li {
  list-style: none;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  z-index: 9;
  display: none;

  &--active {
    display: block;
  }
}

.a-block {
  display: block;
  width: 100%;
  height: 100%;
  color: $light-silver;
}

.container {
  margin: 0 auto;
  max-width: 1372px;
  padding: 0 16px;
  width: 100%;
}

.input-labeled {
  color: $text-dark;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  margin-bottom: 16px;

  &--fill {
    .input-labeled {
      &__label {
        border: 3px;
        top: 8px;
        transform: translateY(0);
        font-size: 12px;
      }
    }
  }

  &--error {
    .input-labeled {
      &__error-text {
        display: block;
      }
      &__input {
        border-color: #c00303;
      }
    }
  }

  &__error-text {
    display: none;
    position: absolute;
    bottom: 0;
    left: 16px;
    color: #c00303;
    font-size: 12px;
    transform: translateY(100%);
  }

  &__input {
    width: 100%;
    padding: 22px 16px 8px 16px;
    border: none;
    outline: none;
    color: $text-dark;
    font-size: 16px;
    background-color: inherit;
    border: 1px solid $outline;
    border-radius: 12px;
  }

  &__label {
    position: absolute;
    transition: 0.3s;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    color: $text-dark;
  }

  &__label-textarea {
    top: 24px;
  }
}

.btn {
  font-size: 16px;
  line-height: 1.5;
  color: white;
  background-color: $button-color;
  padding: 12px 32px;
  border-radius: 48px;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: 0.4s all;
  width: 100%;
  white-space: nowrap;

  &:hover {
    background: $button-hover;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.relative {
  position: relative;
}

@media screen and (min-width: $md) {
  .container {
    padding: 0 30px;
  }
}
