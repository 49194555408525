.mission {
  padding: 100px 0;
  position: relative;

  &__title {
    margin-bottom: 24px;
  }

  &__container {
    position: relative;
  }

  &__info {
    line-height: 1.2;
    font-size: 18px;
  }

  &__img-wrapper {
    position: absolute;
    top: -100px;
    left: 0;
    height: calc(100% + 200px);
    width: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    width: 100%;
    text-align: right;
    position: relative;
    z-index: 15;
    background-color: #ffffffcc;
    padding: 50px 16px;
    border-radius: 16px;
  }
}

@media (min-width: $sm) {
  .mission {
    &__wrapper {
      padding: 100px 0;
      background-color: $header-background;
    }

    &__text {
      width: 50%;
      margin: 0 0 0 auto;
      text-align: right;
      padding: 0;
      background-color: inherit;
    }

    &__img-wrapper {
      left: 16px;
      width: 40%;
      top: -200px;
      height: calc(100% + 400px);
    }
  }
}

@media (min-width: $md) {
  .mission {
    &__img-wrapper {
      left: 30px;
    }
  }
}

@media (min-width: $lg) {
  .mission {
    &__info {
      font-size: 24px;
    }
  }
}
