.banner {
  background-color: $header-background;
  padding: 180px 0;
  background-image: url('./assets/images/content/dubai.jpg');
  backdrop-filter: brightness(1.75);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  &__text {
    background-color: rgba(251, 236, 205, 0.7);
    backdrop-filter: blur(2px);
    position: relative;
    padding: 30px;
    position: relative;

    &:first-child {
      margin-left: auto;
      margin-bottom: 60px;
      border-radius: 16px 0 0 16px;
      border-right: 10px solid $header-background;
    }

    &:last-child {
      border-radius: 0 16px 16px 0;
      border-left: 10px solid $header-background;
    }
  }

  &__title {
    font-size: 28px;
    line-height: 1.5;
    font-family: $font-orbitron;

    &--main {
      text-align: right;
    }

    &--secondary {
      text-align: left;
      margin-bottom: 24px;
      font-size: 20px;
    }
  }
}

@media (min-width: $md) {
  .banner {
    padding: 244px 0 180px 0;

    &__title {
      font-size: 34px;

      &--secondary {
        font-size: 28px;
      }
    }

    &__btn-wrapper {
      width: max-content;
    }
    &__text {
      width: 50%;
    }

    &__btn {
      width: auto;
    }
  }
}
