.footer {
  padding: 104px 0;
  background-color: $text-dark;
  color: $light-silver;

  &__wrapper {
    border-bottom: 1px solid $light-silver;
    margin-bottom: 48px;
  }

  &__logo {
    max-height: 27px;
  }

  &__logo-wrapper {
    margin-bottom: 16px;
  }

  &__address {
    margin-bottom: 16px;
    font-size: 20px;
  }

  &__address-part {
    margin-bottom: 8px;
  }

  &__link {
    text-decoration: none;
    color: $light-silver;
  }
}

@media (min-width: $md) {
  .footer {
    padding: 80px 0;

    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__address-wrapper {
      display: flex;
    }

    &__address {
      text-align: right;

      &:last-child {
        margin-left: 32px;
      }
    }
  }
}
