@import "https://fonts.googleapis.com/css2?family=Arvo&family=Basic&family=Orbitron:wght@400;600&family=Play:wght@400;700&family=Titillium+Web&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  color: #1d1d1f;
  background-color: #fff;
  margin: 0;
  font-family: Play, sans-serif;
  overflow-x: hidden;
}

h1, h2, h3 {
  text-align: center;
  margin-top: 0;
  font-family: Orbitron, sans-serif;
}

h1, h2 {
  line-height: 110%;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 40px;
  line-height: 120%;
}

.section-title {
  font-family: Orbitron, sans-serif;
  font-size: 40px;
  line-height: 1.5;
}

.section-text {
  font-size: 20px;
  line-height: 1.4;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}

li {
  list-style: none;
}

.backdrop {
  backdrop-filter: blur(5px);
  z-index: 9;
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.backdrop--active {
  display: block;
}

.a-block {
  color: #e2e2e7;
  width: 100%;
  height: 100%;
  display: block;
}

.container {
  width: 100%;
  max-width: 1372px;
  margin: 0 auto;
  padding: 0 16px;
}

.input-labeled {
  color: #1d1d1f;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.input-labeled--fill .input-labeled__label {
  border: 3px;
  font-size: 12px;
  top: 8px;
  transform: translateY(0);
}

.input-labeled--error .input-labeled__error-text {
  display: block;
}

.input-labeled--error .input-labeled__input {
  border-color: #c00303;
}

.input-labeled__error-text {
  color: #c00303;
  font-size: 12px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 16px;
  transform: translateY(100%);
}

.input-labeled__input {
  color: #1d1d1f;
  background-color: inherit;
  border: 1px solid #d2d2d7;
  border-radius: 12px;
  outline: none;
  width: 100%;
  padding: 22px 16px 8px;
  font-size: 16px;
}

.input-labeled__label {
  pointer-events: none;
  color: #1d1d1f;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.input-labeled__label-textarea {
  top: 24px;
}

.btn {
  color: #fff;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  background-color: #20603d;
  border: none;
  border-radius: 48px;
  width: 100%;
  padding: 12px 32px;
  font-size: 16px;
  line-height: 1.5;
  transition: all .4s;
}

.btn:hover {
  background: #6dae81;
}

.btn:disabled {
  pointer-events: none;
  opacity: .3;
  cursor: not-allowed;
}

.relative {
  position: relative;
}

@media screen and (width >= 768px) {
  .container {
    padding: 0 30px;
  }
}

.header {
  z-index: 20;
  color: #1d1d1f;
  width: 100%;
  font-family: Play, sans-serif;
  font-size: 15px;
  position: fixed;
  top: 0;
  left: 0;
}

.header__header-outer {
  background-color: #fbeccd;
  box-shadow: 0 2px 8px #00000024;
}

.header__header-wrapper {
  justify-content: space-between;
  align-items: center;
  height: 64px;
  display: flex;
}

.header__link {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.header__right-side {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.header__logo {
  max-height: 24px;
}

.header__logo--black {
  display: none;
}

.header__burger {
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin-left: 24px;
  transition: all .3s cubic-bezier(.77, .2, .05, 1);
  position: relative;
}

.header__burger:before, .header__burger:after {
  content: "";
  will-change: transform;
  background: #1d1d1f;
  width: 36px;
  height: 2px;
  transition: all .25s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
}

.header__burger:before {
  transform: translate(-50%, -6px);
}

.header__burger:after {
  transform: translate(-50%, 6px);
}

.header__burger--active:before {
  transform: translate(-50%)rotate(45deg);
}

.header__burger--active:after {
  transform: translate(-50%)rotate(-45deg);
}

.header__dropdown-wrapper {
  backdrop-filter: blur(5px);
  background: linear-gradient(to top, #6dae81e6, 3%, #fbeccde6);
  width: 70%;
  height: calc(100vh - 60px);
  margin-left: auto;
  transition: transform .3s;
  position: fixed;
  right: 0;
  overflow-y: auto;
  transform: translateX(100%);
}

.header__dropdown-wrapper--active {
  transform: translateX(0);
}

.header__dropdown {
  padding: 60px 0;
  font-family: Orbitron, sans-serif;
  font-size: 24px;
}

.header__dropdown-heading {
  cursor: pointer;
  text-align: right;
  padding: 20px 30px;
}

.header__dropdown-submenu {
  display: none;
}

.header__contact-us-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}

.header__contact-us {
  cursor: pointer;
}

.header__contact-us svg {
  width: 40px;
  height: 40px;
  display: none;
}

.header__contact-us-text {
  white-space: nowrap;
}

.header__dropdown-link {
  color: #1d1d1f;
  text-decoration: none;
}

.header__dropdown-link:visited {
  color: #1d1d1f;
}

.header__nav-desktop {
  display: none;
}

.header__nav-desktop-item {
  cursor: pointer;
  height: 100%;
  margin-right: 48px;
  padding: 24px 0;
  transition: all .3s;
}

.header__nav-desktop-item:last-child {
  margin-right: 0;
}

.header__desktop-heading {
  color: #1d1d1f;
  transition: all .3s;
}

.header__desktop-heading:hover {
  color: #20603d;
}

@media (width >= 576px) {
  .header__dropdown-wrapper {
    width: 50%;
  }
}

@media (width >= 992px) {
  .header {
    position: absolute;
  }

  .header__burger {
    display: none;
  }

  .header__desktop-header {
    width: 20%;
  }

  .header__contact-us-wrapper {
    justify-content: flex-end;
  }

  .header__nav-desktop {
    align-items: center;
    height: 100%;
    display: flex;
  }

  .header__contact-us {
    cursor: pointer;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    transition: all .3s;
    display: flex;
  }

  .header__contact-us svg {
    margin-left: 16px;
    display: inline-block;
  }

  .header__contact-us .header__contact-us-svg {
    transition: all .3s;
  }

  .header__contact-us:hover {
    color: #20603d;
  }

  .header__contact-us:hover .header__contact-us-svg {
    stroke: #20603d;
  }
}

.footer {
  color: #e2e2e7;
  background-color: #1d1d1f;
  padding: 104px 0;
}

.footer__wrapper {
  border-bottom: 1px solid #e2e2e7;
  margin-bottom: 48px;
}

.footer__logo {
  max-height: 27px;
}

.footer__logo-wrapper {
  margin-bottom: 16px;
}

.footer__address {
  margin-bottom: 16px;
  font-size: 20px;
}

.footer__address-part {
  margin-bottom: 8px;
}

.footer__link {
  color: #e2e2e7;
  text-decoration: none;
}

@media (width >= 768px) {
  .footer {
    padding: 80px 0;
  }

  .footer__wrapper {
    justify-content: space-between;
    display: flex;
  }

  .footer__address-wrapper {
    display: flex;
  }

  .footer__address {
    text-align: right;
  }

  .footer__address:last-child {
    margin-left: 32px;
  }
}

.banner {
  backdrop-filter: brightness(1.75);
  background-color: #fbeccd;
  background-image: url("dubai.f5b5ef39.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 180px 0;
}

.banner__text {
  backdrop-filter: blur(2px);
  background-color: #fbeccdb3;
  padding: 30px;
  position: relative;
}

.banner__text:first-child {
  border-right: 10px solid #fbeccd;
  border-radius: 16px 0 0 16px;
  margin-bottom: 60px;
  margin-left: auto;
}

.banner__text:last-child {
  border-left: 10px solid #fbeccd;
  border-radius: 0 16px 16px 0;
}

.banner__title {
  font-family: Orbitron, sans-serif;
  font-size: 28px;
  line-height: 1.5;
}

.banner__title--main {
  text-align: right;
}

.banner__title--secondary {
  text-align: left;
  margin-bottom: 24px;
  font-size: 20px;
}

@media (width >= 768px) {
  .banner {
    padding: 244px 0 180px;
  }

  .banner__title {
    font-size: 34px;
  }

  .banner__title--secondary {
    font-size: 28px;
  }

  .banner__btn-wrapper {
    width: max-content;
  }

  .banner__text {
    width: 50%;
  }

  .banner__btn {
    width: auto;
  }
}

.ticker {
  color: #e2e2e7;
  background-color: #20603d;
  padding: 60px 0;
}

.ticker__title {
  text-align: center;
  margin-bottom: 36px;
}

.ticker__container {
  flex-wrap: nowrap;
  display: flex;
  overflow: hidden;
}

.ticker--top {
  margin-bottom: 24px;
}

.ticker--bottom {
  padding-bottom: 96px;
}

.ticker__wrapper {
  align-items: center;
  animation: 50s linear infinite scroll;
  display: flex;
}

.ticker__item {
  text-transform: uppercase;
  color: #20603d;
  -webkit-text-stroke: 1px #e2e2e7;
  text-stroke: 1px #e2e2e7;
  white-space: nowrap;
  margin: 0 40px;
  font-family: Orbitron, sans-serif;
  font-size: 120px;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.advantages {
  color: #e2e2e7;
  background-color: #20603d;
  padding: 96px 0;
  overflow: hidden;
}

.advantages__title {
  text-align: left;
  margin-bottom: 24px;
}

.advantages__section-item {
  height: max-content;
  margin-bottom: 40px;
}

.advantages__section-img {
  width: 100%;
  height: 30%;
  display: block;
}

.advantages__section-title {
  margin-bottom: 16px;
  font-family: Orbitron, sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

.advantages__section-info {
  font-size: 16px;
  line-height: 1.2;
}

@media (width >= 576px) {
  .advantages__section-img {
    width: 60%;
    margin: 0 auto;
  }
}

@media (width >= 768px) {
  .advantages__section-wrapper {
    justify-content: space-between;
    display: flex;
  }

  .advantages__section-img {
    width: 80%;
    height: 30%;
  }

  .advantages__section-item {
    max-width: 45%;
    margin-bottom: 0;
  }
}

@media (width >= 992px) {
  .advantages {
    padding: 136px 0;
  }

  .advantages__info-wrapper {
    justify-content: space-between;
    display: flex;
  }

  .advantages__text {
    width: 45%;
  }

  .advantages__section-title {
    font-size: 24px;
  }

  .advantages__section-info {
    font-size: 20px;
  }
}

.contacts {
  color: #1d1d1f;
  background-color: #fff;
  padding: 104px 0;
}

.contacts__wrapper {
  position: relative;
}

.contacts__title {
  margin-bottom: 32px;
}

.contacts__img {
  object-fit: cover;
  border-radius: 16px 0 0 16px;
  width: 100%;
  height: 100%;
}

.contacts__img-wrapper {
  width: 90%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.contacts__form-wrapper {
  max-width: 100%;
  margin-left: auto;
  padding: 64px 0;
}

.contacts__form {
  z-index: 5;
  backdrop-filter: blur(4px);
  background-color: #ffffffd4;
  border-radius: 16px;
  padding: 40px 16px;
  position: relative;
  box-shadow: 0 2px 8px #00000024;
}

.contacts__input {
  background-color: #ffffffb8;
}

@media (width >= 576px) {
  .contacts__form-wrapper {
    max-width: 70%;
  }
}

@media (width >= 768px) {
  .contacts {
    padding: 136px 0;
  }

  .contacts__form-wrapper {
    max-width: 50%;
  }

  .contacts__img-wrapper {
    width: 70%;
  }
}

.mission {
  padding: 100px 0;
  position: relative;
}

.mission__title {
  margin-bottom: 24px;
}

.mission__container {
  position: relative;
}

.mission__info {
  font-size: 18px;
  line-height: 1.2;
}

.mission__img-wrapper {
  width: 100%;
  height: calc(100% + 200px);
  position: absolute;
  top: -100px;
  left: 0;
}

.mission__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.mission__text {
  text-align: right;
  z-index: 15;
  background-color: #fffc;
  border-radius: 16px;
  width: 100%;
  padding: 50px 16px;
  position: relative;
}

@media (width >= 576px) {
  .mission__wrapper {
    background-color: #fbeccd;
    padding: 100px 0;
  }

  .mission__text {
    text-align: right;
    background-color: inherit;
    width: 50%;
    margin: 0 0 0 auto;
    padding: 0;
  }

  .mission__img-wrapper {
    width: 40%;
    height: calc(100% + 400px);
    top: -200px;
    left: 16px;
  }
}

@media (width >= 768px) {
  .mission__img-wrapper {
    left: 30px;
  }
}

@media (width >= 992px) {
  .mission__info {
    font-size: 24px;
  }
}

.industries {
  padding: 104px 0;
}

.industries__title {
  margin-bottom: 24px;
}

.industries__wrapper {
  grid-template-columns: repeat(1, 100%);
  gap: 16px;
  padding: 48px 0;
  display: grid;
}

.industries__item {
  text-align: center;
  border-radius: 16px;
  grid-auto-rows: 1fr;
  width: 100%;
  max-width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.industries__item:hover .industries__img {
  transform: scale(1.2);
}

.industries__item:hover .industries__name:before, .industries__item:hover .industries__name:after {
  width: 100%;
}

.industries__img-wrapper {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.industries__img {
  object-fit: cover;
  z-index: 0;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  transition: all .6s;
  display: flex;
}

.industries__name {
  z-index: 10;
  backdrop-filter: blur(4px);
  background-color: #fadfad99;
  padding: 10px 0;
  font-size: 24px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.industries__name:before, .industries__name:after {
  content: "";
  background-color: #fbeccd;
  width: 0;
  height: 2px;
  transition: all .65s;
  position: absolute;
}

.industries__name:before {
  bottom: 100%;
  left: 0;
}

.industries__name:after {
  top: 100%;
  right: 0;
}

@media (width >= 768px) {
  .industries {
    padding: 136px 0;
  }

  .industries__wrapper {
    grid-template-columns: repeat(2, 49%);
    gap: 16px;
    padding: 64px 0;
  }
}

@media (width >= 992px) {
  .industries__wrapper {
    grid-template-columns: repeat(3, calc(32% + 6px));
  }

  .industries__text-wrapper {
    display: flex;
  }

  .industries__title {
    width: 34%;
  }

  .industries__text {
    width: 66%;
  }
}

.checkbox {
  color: #1d1d1f;
  position: relative;
}

.checkbox__input {
  appearance: none;
}

.checkbox__label {
  cursor: pointer;
  padding-left: 32px;
  display: inline-block;
}

.checkbox__label:before {
  content: "";
  z-index: 1;
  background-color: #fff;
  border: 1px solid #d2d2d7;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}

.checkbox__label:after {
  content: "";
  z-index: 2;
  background-size: 14px 14px;
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
}

.checkbox__input:checked + .checkbox__label:before {
  background-color: #20603d;
}

.checkbox__input:checked + .checkbox__label:after {
  background-image: url("checkbox-arrow.1b5c2f42.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox__link {
  color: #1d1d1f;
  text-decoration: underline;
}

.checkbox__link:hover {
  color: #20603d;
}

.checkbox__header {
  margin-top: 24px;
  margin-bottom: 24px;
}

.checkbox__contacts {
  margin-top: 32px;
  margin-bottom: 32px;
}

.form__textarea-wrapper {
  margin-bottom: 0;
}

.form__textarea-wrapper textarea {
  resize: none;
  font-family: Play, sans-serif;
  display: block;
}

.form__checkbox-wrapper {
  margin: 32px 0;
}

.modal-form {
  z-index: 20;
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
}

.modal-form--active {
  display: block;
}

.modal-form__wrapper {
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 16px;
  right: 16px;
  transform: translateY(-50%);
}

.modal-form__form {
  color: #1d1d1f;
  background-color: #fff;
  border-radius: 24px;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: Play, sans-serif;
  font-size: 15px;
  box-shadow: 0 0 34px -10px #1d1d1f;
}

.modal-form__form .form__checkbox-wrapper {
  margin: 24px 0;
}

.modal-form__close {
  cursor: pointer;
  background-image: url("close-button.16e4104a.svg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  align-items: center;
  width: max-content;
  height: 40px;
  margin-bottom: 24px;
  margin-left: auto;
  padding-right: 56px;
  transition: all .3s;
  display: flex;
}

.modal-form__title {
  font-family: Play, sans-serif;
  font-size: 20px;
  line-height: 120%;
}

.modal-form__text {
  margin: 24px 0;
  font-family: Play, sans-serif;
  font-size: 14px;
  line-height: 150%;
}

@media (width >= 992px) {
  .modal-form__wrapper {
    max-width: 504px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
/*# sourceMappingURL=index.e8889cdf.css.map */
