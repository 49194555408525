.header {
  font-family: 'Play', sans-serif;
  font-size: 15px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  color: $text-dark;

  &__header-outer {
    background-color: $header-background;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
  }

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
  }

  &__link {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &__right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__logo {
    max-height: 24px;

    &--black {
      display: none;
    }
  }

  &__burger {
    width: 48px;
    height: 48px;
    margin-left: 24px;
    transition: all 250ms ease-out;
    cursor: pointer;
    position: relative;
    transition: 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);

    &:before,
    &:after {
      content: '';
      width: 36px;
      height: 2px;
      top: 50%;
      left: 50%;
      position: absolute;
      background: $text-dark;
      transition: all 250ms ease-out;
      will-change: transform;
    }

    &:before {
      transform: translate(-50%, -6px);
    }

    &:after {
      transform: translate(-50%, 6px);
    }

    &--active {
      &:before {
        transform: translate(-50%, 0) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, 0) rotate(-45deg);
      }
    }
  }

  &__dropdown-wrapper {
    height: calc(100vh - 60px);
    position: fixed;
    overflow-y: auto;
    backdrop-filter: blur(5px);
    background: linear-gradient(
      to top,
      rgba(109, 174, 129, 0.9),
      3%,
      rgba(251, 236, 205, 0.9)
    );
    width: 70%;
    margin-left: auto;
    transform: translateX(100%);
    transition: 0.3s transform;
    right: 0;

    &--active {
      transform: translateX(0);
    }
  }

  &__dropdown {
    padding: 60px 0;
    font-family: $font-orbitron;
    font-size: 24px;
  }

  &__dropdown-heading {
    padding: 20px 30px;
    cursor: pointer;
    text-align: right;
  }

  &__dropdown-submenu {
    display: none;
  }

  &__contact-us-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__contact-us {
    cursor: pointer;

    svg {
      display: none;
      width: 40px;
      height: 40px;
    }
  }

  &__contact-us-text {
    white-space: nowrap;
  }

  &__dropdown-link {
    text-decoration: none;
    color: $text-dark;

    &:visited {
      color: $text-dark;
    }
  }
}

.header {
  &__nav-desktop {
    display: none;
  }

  &__nav-desktop-item {
    margin-right: 48px;
    height: 100%;
    padding: 24px 0;
    cursor: pointer;
    transition: 0.3s;

    &:last-child {
      margin-right: 0;
    }
  }

  &__desktop-heading {
    transition: 0.3s;
    color: $text-dark;

    &:hover {
      color: $button-color;
    }
  }
}

@media (min-width: $sm) {
  .header {
    &__dropdown-wrapper {
      width: 50%;
    }
  }
}

@media (min-width: $lg) {
  .header {
    position: absolute;

    &__burger {
      display: none;
    }
    &__desktop-header {
      width: 20%;
    }

    &__contact-us-wrapper {
      justify-content: flex-end;
    }

    &__nav-desktop {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &__contact-us {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: 0.3s;
      cursor: pointer;

      svg {
        margin-left: 16px;
        display: inline-block;
      }

      .header__contact-us-svg {
        transition: 0.3s;
      }

      &:hover {
        color: $button-color;

        .header__contact-us-svg {
          stroke: $button-color;
        }
      }
    }
  }
}
