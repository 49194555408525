.modal-form {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 20;

  &--active {
    display: block;
  }

  &__wrapper {
    position: fixed;
    top: 50%;
    left: 16px;
    right: 16px;
    transform: translateY(-50%);
    z-index: 10;
  }

  &__form {
    background-color: $background;
    width: 100%;
    border-radius: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    color: $text-dark;
    font-size: 15px;
    box-shadow: 0 0px 34px -10px $text-dark;
    font-family: 'Play', sans-serif;

    .form__checkbox-wrapper {
      margin: 24px 0;
    }
  }

  &__close {
    padding-right: 56px;
    height: 40px;
    display: flex;
    align-items: center;
    background-image: url('assets/images/icons/close-button.svg');
    background-repeat: no-repeat;
    background-position: 100% 0;
    transition: 0.3s;
    cursor: pointer;
    margin-bottom: 24px;
    width: max-content;
    margin-left: auto;
  }

  &__title {
    font-family: 'Play', sans-serif;
    font-size: 20px;
    line-height: 120%;
  }

  &__text {
    margin: 24px 0;
    font-family: 'Play', sans-serif;
    font-size: 14px;
    line-height: 150%;
  }
}

@media (min-width: $lg) {
  .modal-form {
    &__wrapper {
      max-width: 504px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
