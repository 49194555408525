.industries {
  padding: 104px 0;

  &__title {
    margin-bottom: 24px;
  }

  &__wrapper {
    display: grid;
    gap: 16px;
    padding: 48px 0;
    grid-template-columns: repeat(1, 100%);
  }

  &__item {
    width: 100%;
    height: 200px;
    grid-auto-rows: 1fr;
    position: relative;
    max-width: 100%;
    text-align: center;
    position: relative;
    border-radius: 16px;
    overflow: hidden;

    &:hover {
      .industries {
        &__img {
          transform: scale(1.2);
        }

        &__name {
          &:before,
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  &__img-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 16px;
  }

  &__img {
    display: flex;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    z-index: 0;
    transition: 0.6s;
  }

  &__name {
    position: relative;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(250, 223, 173, 0.6);
    font-size: 24px;
    padding: 10px 0;
    backdrop-filter: blur(4px);

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: $header-background;
      width: 0;
      height: 2px;
      transition: 0.65s;
    }

    &:before {
      bottom: 100%;
      left: 0;
    }

    &:after {
      top: 100%;
      right: 0;
    }
  }
}

@media (min-width: $md) {
  .industries {
    padding: 136px 0;

    &__wrapper {
      grid-template-columns: repeat(2, 49%);
      gap: 16px;
      padding: 64px 0;
    }
  }
}

@media (min-width: $lg) {
  .industries {
    &__wrapper {
      grid-template-columns: repeat(3, calc(32% + 6px));
    }

    &__text-wrapper {
      display: flex;
    }

    &__title {
      width: 34%;
    }
    &__text {
      width: 66%;
    }
  }
}
