.checkbox {
  color: $text-dark;
  position: relative;

  &__input {
    -webkit-appearance: none;
    appearance: none;
  }

  &__label {
    padding-left: 32px;
    cursor: pointer;
    display: inline-block;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid $outline;
      background-color: #ffffff;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      background-size: 14px 14px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 7px;
      z-index: 2;
    }
  }

  &__input:checked + &__label:before {
    background-color: $button-color;
  }

  &__input:checked + &__label:after {
    background-image: url('assets/images/icons/checkbox-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &__link {
    text-decoration: underline;
    color: $text-dark;

    &:hover {
      color: $button-color;
    }
  }

  &__header {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__contacts {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
